import type { JsonListenResponse, RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';
import type { Dayjs, IsoDateString } from 'src/utils/formatting/dates';
import type {
  JsonBalanceAsset,
  JsonBalanceExtended,
  JsonBalanceHistoryExtended,
} from 'src/store/shared/balances/types';
// import type { SetInitialBalancePayload } from './actions';

export enum TransactionCommands {
  Transactions = 'Transactions',
  GetTransactions = 'GetTransactions',
  AddTransaction = 'AddTransaction',
  UpdateTransaction = 'UpdateTransaction',
  RemoveTransaction = 'RemoveTransaction',
}

export type JsonTransactionsListenResponse = JsonListenResponse<
  TransactionCommands.Transactions,
  { list: JsonTransaction[] }
>;

export interface AddManualTransactionPayload {
  accountID: string;
  date?: string; // YYYY-MM-DDThh:mm:ss
  amount: number;
  token: string;
  comment?: string;
  closeAfterSubmit?: boolean;
}

export interface RemoveManualTransactionPayload {
  transactionID: string;
  accountID: string;
}

export interface UpdateTransactionPayload {
  id: string;
  comment: string;
}

export interface GetTransactionsPayload {
  accountID?: string;
}

export type TransactionType = 'Deposit' | 'Withdraw';

export interface JsonSummaryTransaction {
  date: IsoDateString;
  currency: string;
  volume: number;
  volumeUSD: number; // USD equivalent
}

export type TransactionObj = Record<
  string,
  Partial<Record<TransactionType, Partial<JsonSummaryTransaction>>>
>;

export type ManualTransactionFormValues = AddManualTransactionPayload & {
  // type: 'base' | 'quote' | 'other';
  transactionType?: TransactionType;
  mDate?: Dayjs; // YYYY-MM-DD
  mTime?: Dayjs; // hh:mm
  meta?: ManualTransactionMetaData | JsonBalanceExtended | JsonBalanceHistoryExtended;
};

export interface JsonTransaction {
  id: string;
  accountID: string;
  accountName: string;
  exchange: string;
  auto: boolean;
  date: IsoDateString;
  type: TransactionType;
  currency: string;
  txID: string;
  volume: number;
  usd: number; // USD equivalent
  fee: number;
  comment: string;
}

// export type JsonManualTransactionsSuccess = {
//   command: ManualTransactionsCommands.ManualTransactions;

//   list: JsonManualTransaction[];
// };

export type TransactionsSortByOrder = 'name:asc' | 'name:desc' | 'date:asc' | 'date:desc';
export type TransactionsViewFilterType = 'auto' | 'manual' | 'noFilter';

export interface ManualTransactionMetaData {
  assets: JsonBalanceAsset[];
  accountTokens: string[];
}

export interface TransactionsState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonTransaction[];

  // startDate?: string; // YYYY-MM-DD
  // endDate?: string; // YYYY-MM-DD

  // create manual transaction
  isManualTransactionModalOpen: boolean;
  formManualTransaction?: AddManualTransactionPayload;
  metaManualTransaction?: ManualTransactionMetaData;
  resManualTransaction: RequestStatus;

  closeAfterSubmit: boolean;

  viewSortByOrder: TransactionsSortByOrder;
  viewFilterByType: TransactionsViewFilterType;
}
