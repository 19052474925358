import React, { useContext } from 'react';
import type { ReactNode } from 'react';

import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import min from 'lodash/min';
import max from 'lodash/max';
import includes from 'lodash/includes';
import keys from 'lodash/keys';

import { Popover, Skeleton, Space, Typography } from 'antd';
import type { Options } from 'react-copy-to-clipboard';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import type { PopoverProps, SpaceProps } from 'antd';
import type { FormatNumberOptions, IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import type { CopyConfig } from 'antd/es/typography/Base';
import NotificationContext from 'src/contexts/notification';

export interface SkyNumberProps {
  // text?: string | ReactNodeLike;
  className?: string;
  loading?: boolean;
  tooltip?:
    | string
    | ((value: number | string) => string | ReactNode)
    | ReactNode
    | {
        content?: string | ReactNode | ((value: number | string) => string | ReactNode);
        title?: string;
        trigger?: PopoverProps['trigger'];
      };
  value?: number;
  format?: 'engineering' | 'compactLong' | 'scientific' | 'short' | 'precision' | 'highPrecision';
  currency?: string;
  signDisplay?: 'auto' | 'always' | 'never' | 'exceptZero';
  numberStyle?: 'currency' | 'decimal' | 'percent' | 'unit';
  copyable?: boolean | CopyConfig;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  addonOffset?: SpaceProps['size'];
  addonDirection?: 'horizontal' | 'vertical';
  addonAlign?: SpaceProps['align'];
  debug?: boolean;
  wrapperCopy?: boolean;
  wrapperOption?: {
    children?: React.ReactNode;
    text?: string;
    onCopy?(text: string, result: boolean): void;
    options?: Options | undefined;
  };
}

const cryptoCurrencies: Record<string, string> = {
  USDT: '$', // '₮'
  USDC: '$',
  BUSD: '$',
  EURT: '€',
  BTC: '₿',
  XBT: '₿',
};

const supportedCurrencies = [...Intl.supportedValuesOf('currency'), ...keys(cryptoCurrencies)];

export const getNumberFormatter =
  (intl: IntlShape) => (value?: number | null, opts?: FormatNumberOptions) => {
    if (isNil(value)) {
      return 'N/A';
    }

    const getNumOpts = () => {
      const defaultOpts: FormatNumberOptions = {
        ...opts,
        ...(opts?.currency
          ? {
              currency: opts.currency,
              style: includes(supportedCurrencies, opts?.currency) ? 'currency' : opts.style,
              currencyDisplay: 'narrowSymbol',
            }
          : {}),
      };

      // override default options for currency format
      if (opts?.currency && !defaultOpts.minimumFractionDigits) {
        const absValue = Math.abs(value);
        if (absValue === 0) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 0;
          }
        } else if (absValue > 0 && absValue <= 0.0001) {
          defaultOpts.maximumSignificantDigits = 2;
        } else if (absValue > 0.0001 && absValue <= 1) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 2;
            defaultOpts.maximumSignificantDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
          if (defaultOpts.format === 'highPrecision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
        } else if (absValue > 1 && absValue <= 10) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 1;
            defaultOpts.maximumFractionDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumSignificantDigits = 3;
          }
          if (defaultOpts.format === 'highPrecision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
        } else if (absValue > 10 && absValue <= 1_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 0;
            defaultOpts.maximumFractionDigits = 1;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 1;
          }
        } else if (absValue > 1_000 && absValue <= 1_000_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 1;
            defaultOpts.maximumFractionDigits = 1;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 0;
          }
        } else if (absValue > 1_000_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 2;
            defaultOpts.maximumFractionDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 0;
          }
        } else if (defaultOpts.format === 'compactLong') {
          defaultOpts.minimumFractionDigits = 2;
          defaultOpts.maximumFractionDigits = 2;
        }
      }

      return {
        ...defaultOpts,

        // make sure we don't have errors with minimumFractionDigits and maximumFractionDigits
        ...(opts?.minimumFractionDigits !== undefined && {
          minimumFractionDigits: min([
            opts?.minimumFractionDigits,
            defaultOpts.minimumFractionDigits,
          ]),
        }),
        ...(opts?.maximumFractionDigits !== undefined && {
          maximumFractionDigits: max([
            opts?.maximumFractionDigits,
            defaultOpts.maximumFractionDigits,
          ]),
        }),
      };
    };

    const formatOpts = getNumOpts();

    // for known crypto currencies, replace the currency symbol
    if (opts?.currency && !isNil(cryptoCurrencies[opts?.currency])) {
      return intl
        .formatNumber(value, {
          ...formatOpts,
          currency: 'USD',
        })
        .replace('$', cryptoCurrencies[opts?.currency]);
    }

    try {
      return intl.formatNumber(value, formatOpts);
    } catch (ignoreError) {
      // NOTICE: this will cause tons of error logs in the console
      // which dramatically reduce the performance in the dev mode
      // console.error('Failed to format number', error);

      // fallback to USD if currency is not supported,
      // e.g. unknown crypto currency is replaced with currency name
      if (opts?.currency) {
        return intl.formatNumber(value, {
          ...formatOpts,
          currency: 'USD',
          style: 'decimal',
        });
      }

      // fallback to unformatted value as string
      return `${value}`;
    }
  };

export default function SkyNumber<T extends SkyNumberProps>({
  className = '',
  loading = false,
  tooltip = undefined,
  format,
  value,
  numberStyle,
  signDisplay,
  currency,
  copyable,
  minimumFractionDigits,
  maximumFractionDigits,
  addonBefore,
  addonAfter,
  addonOffset = 0,
  addonDirection = 'vertical',
  addonAlign = undefined,
  wrapperCopy = false,
  wrapperOption,
  // debug = false,
}: Readonly<T>) {
  const intl = useIntl();
  // TODO: refactor with app-level message context
  const { messageApi } = useContext(NotificationContext);
  const cls = `tracking-[1px] ${className}`;

  if (loading) {
    return <Skeleton active paragraph={{ rows: 1 }} />;
  }

  let frmt = format;
  if (format === 'short' || !format) {
    if (!isNil(value) && Math.abs(value) >= 10000) {
      frmt = format !== 'short' && format ? format : 'compactLong';
    } else if (!isNil(value) && Math.abs(value) > 0 && Math.abs(value) <= 0.0001) {
      frmt = format !== 'short' ? 'highPrecision' : 'scientific';
    }
  }

  const defaultOpts: FormatNumberOptions = {
    format: frmt,
    // style: currency ? 'currency' : numberStyle,
    // currencyDisplay: 'narrowSymbol',
    style: numberStyle,
    currency,
    signDisplay,
  };

  const formatNumber = getNumberFormatter(intl);

  const customFormat = {
    ...defaultOpts,
    ...(minimumFractionDigits !== undefined && { minimumFractionDigits }),
    ...(maximumFractionDigits !== undefined && { maximumFractionDigits }),
  };
  const customOutput = formatNumber(value, customFormat);

  let tooltipText = '';
  if (!isNil(value)) {
    const tooltipOutput = formatNumber(value, {
      ...customFormat,
      format: 'precision',
    });

    if (tooltipOutput !== customOutput) {
      tooltipText = `${tooltipOutput || value}`;
    }

    if (!tooltipText) {
      tooltipText = `${tooltipOutput || value}`;
    }
  }

  const tooltipOpts = {
    trigger: 'hover' as PopoverProps['trigger'],
    content: (isFunction(tooltip)
      ? tooltip(tooltipText)
      : (tooltip as string) || tooltipText) as PopoverProps['content'],
    ...(isObject(tooltip) ? tooltip : {}),
  };

  const Component = (
    <Popover {...tooltipOpts}>
      <Space direction={addonDirection} size={addonOffset} align={addonAlign}>
        {addonBefore}
        <span className={cls}>{customOutput}</span>
        {addonAfter}
      </Space>
    </Popover>
  );

  let copyValue: string | number | undefined = value;

  if (!isNil(value)) {
    copyValue = formatNumber(value, {
      ...defaultOpts,
      currency: undefined,
      style: 'decimal',
      format: 'highPrecision',
      useGrouping: false,
    });
  }

  if (copyable) {
    const copyOpts = isObject(copyable) ? copyable : {};
    // when you change size of SkyButton it should be changed respectively
    const iconCls = 'w-[32px] h-[36px] flex items-center justify-center';
    return (
      <Typography.Text
        copyable={{
          text: `${copyValue}`,
          icon: [
            <CopyOutlined key="copy" className={iconCls} />,
            <CheckOutlined key="check" className={iconCls} />,
          ],
          ...copyOpts,
        }}
      >
        {Component}
      </Typography.Text>
    );
  }

  if (wrapperCopy) {
    const copyOption = {
      onCopy: (text: string, result: boolean) => {
        if (result) {
          messageApi.info('value copied into clipboard');
        }
      },
      ...wrapperOption,
    };

    return (
      <>
        <CopyToClipboard text={`${copyValue}`} {...copyOption}>
          {Component}
        </CopyToClipboard>
      </>
    );
  }

  return Component;
}
