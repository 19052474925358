import type { JsonProjectAdmin, JsonUserAccount } from 'src/store/retainers/admin-panel/slice';

export enum AdminPanelCommands {
  AdminPanel = 'AdminPanel',
}

export interface JsonAdminPanelResponse {
  command: AdminPanelCommands.AdminPanel;
  accounts: JsonUserAccount[];
  projects: JsonProjectAdmin[];
}
