import type { GenericError } from 'src/store/utils/errors';
import type { JsonWsSuccessResponse, RequestStatus } from 'src/store/types';
import type { JsonAccount } from 'src/store/shared/accounts/types';

import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { LogItem } from 'src/store/shared/types';
import type { IsoDateString } from 'src/utils/formatting/dates';

export enum LoanCommands {
  Loans = 'Loans',
  Overview = 'Overview',

  Balances = 'Balances',
  History = 'History',
  BalanceHistory = 'BalanceHistory',

  AddLoan = 'AddLoan',
  UpdateLoan = 'UpdateLoan',
  UpdateComment = 'UpdateComment',
  HideLoan = 'HideLoan',
  Log = 'Log',

  LoanLiquidity = 'LoanLiquidity',
}

export const KpiTarget = {
  Spread: 'Spread',
  Depth: 'Depth',
} as const;

export interface LoansState {
  error: null | GenericError;
  listenStatus: RequestStatus;

  // Loans, data on Login
  list: JsonLoan[] | null;
  companies: string[];

  // Overview, data on Listen/Overview
  comments?: JsonLoanComment[];
  isOverviewLoading: boolean;

  // Accounts, data on Listen/Exchange

  // Loan ApiKeyModals and Loan terms
  isNewLoanModalOpen: boolean;
  resNewLoan: RequestStatus;
  resUpdateLoan: RequestStatus;

  // Loan comments and ApiKeyModals
  isNewLoanCommentModalOpen: boolean;
  resUpdateComment: RequestStatus;

  // Loans (right-hand) sidebar and header
  isSidebarOpen: boolean;
  targetLoan: string;
  targetExchange: string;

  overviewActiveTab: string;

  termChangesLog: LogItem[];
  termsChangesLogLoadingStatus: RequestStatus;

  // sort
  searchInput: string;
  sortKey: string;
}

export interface JsonLoanExchangeBots {
  id: number;
  bots: number;
}

export interface JsonLoan {
  id: number;
  name: string;
  exchanges: JsonLoanExchangeBots[];
}

export interface JsonLoansSuccess {
  command: 'Loans';

  list: JsonLoan[];
  companies: string[];
}

export type JsonAddLoanSuccess = JsonWsSuccessResponse & {
  result: string;
};

export interface JsonLinechartPart {
  date: string;
  value: number;
}

export type JsonLinechartOverview = JsonLinechartPart[];

// deprecated
export interface JsonLoanComment {
  id: number;
  comment: string;
  author: string;
  date: string; // DD.MM.YYYY hh:mm
}

// deprecated
// export type JsonOverviewSuccess = {
//   command: 'Overview';
//   token: String;
//   comments: JsonLoanComment[];
//   details: JsonLoanDetails;
// };

export interface JsonLoanPair {
  id: number; // ?
  accountID: string;
  account?: JsonAccount;
  groupID: string; // could be with letters,
  subID: string; // SubAccount ID, could be letters
  direction: 'Buy' | 'Sell';
  exchangeID: number; // ?
  exchange?: JsonExchange;
  pair: string; // ?
  lastPrice: number;
  startPrice: number;
  endPrice: number; // ?
  bid: number; // ?
  ask: number; // ?
  spread: string;
  priceStep: string;
  buyOrders: number;
  sellOrders: number;
  orderSize: string;
  fixVolume: boolean; // ?
  volumeAll: number; // ?
  tradeVolume: number; // ?
  forceLimits: string; // ?
  status: number;
  taskStatus: number;
  orderPeriod: number; // ?
  ordersExecuted: string; // ?
  profit: number;
  dailyProfit: number;
  deltaVolume: number;
  deltaPrice: number;
  autoBalanceLevel: number;
  inviteLink: string;
  scale: number; // ?
  treshold: number; // ?
  plusDepth: string;
  minusDepth: string;
  rangeUp: string;
  rangeDown: string;
  range: string;
  statusError: string;
  quote: number;
  stable: number;
  ratio: number;

  startDate: string;
  threshold: number;
  avgPrice: number;
  eta?: number;
  // unfilledSlots: any;
  level?: string;
  liquidity: Record<ActualDepth, number>;
  formattedLiquidity: FormattedLiquidityMap;
  dailyVolume: number;
  //TODO: check after backend update
  estimated?: string;
  limitPrice?: number;
  dca?: number;
  totalDepth?: number;
  firstOrder?: number;
  lastOrder?: number;
}

export type ActualDepth = '1' | '2' | '5' | '10' | '-1' | '-2' | '-5' | '-10';

export interface LiquidityValue {
  liquidity: number;
}

export interface LiquidityPropItem extends LiquidityValue {
  avgLiquidity: number;
  avgBotsLiquidity?: number;
  avgProjectLiquidity?: number;
  avgLiquidityByDate: [string, number][];
  isActual: boolean;
  difference?: number;
  isMismatch?: boolean;
  kpi: number;
  actualLiquidityUpdateTime?: IsoDateString;
}

export type DepthDataMap = Partial<Record<ActualDepth, LiquidityPropItem>>;
export type FormattedLiquidityMap = Record<ActualDepth, LiquidityValue>;
